import React from "react";
import SlideTitle from "./SlideTitle";
import SlideImage from "./SlideImage";

import "./../styles.scss";

export default function Slide({ data: { data, name } }) {

 name = name.slice(1);
 name = name.split('.')[0]
  return (
    <div className="slide">
      <SlideImage src={data} alt={name} />
      <SlideTitle title={name} />
    </div>
  );
}

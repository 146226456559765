export class ClientBase{
    protected transformOptions(transformOptions: RequestInit)
    {
        const token = localStorage.getItem('token');
        transformOptions.headers = {
            ...transformOptions.headers,
            Authorization: 'Bearer ' + token
        };
        return Promise.resolve(transformOptions);
    }
}
import React from 'react'
import { Client } from '../Api/Api.ts'
import { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import parse from 'html-react-parser'

import Document from "./Document.js"


import Slider from "./widgets/Slider/Slider.js"






export default function Notification({noteName, apiClient}) {
  const [about, setAbout] = useState('')

  const [images, setImages] = useState([])
  
  const [documents, setDocuments] = useState([])

  const [title, setTitle] = useState('')

  const getImages = async (noteId, imageName) => {
    let result = apiClient.notificationGET3(noteId, imageName);
    
    return result;
  }

  async function getResult() {    
  let result = await apiClient.notificationGET2(noteName)      
  setAbout(result.text)
  setImages(result.images)
  setTitle(result.title)
  setDocuments(result.documentNames)
}

useEffect(() => {
  setTimeout(getResult, 700)
}, []);

  
  const suii = about.split('\n');


  return (
    <div className='about'>
        <div className='titleOfNote'>{title}</div>
        {suii.map(el => (
          <div className='element'>
          {parse(el)}
          </div>
        ))}
        <div className='docksInNote'>
        {documents ? documents.map(el => ( 
  <Document docName={el.slice(1)} apiClient={apiClient} />
)) : ''}
      </div>
        {images ? (<Slider itemsB4={images.sort((a, b) => a.name.localeCompare(b.name))} client={apiClient} getImages={getImages}/>) : ''}
    
    </div>
  )
}

/*

Notification.propTypes = {
  noteName: PropTypes.string,
  apiClient: Client




     {suii.map(el => (
          <p className='element'>{el}</p>
        ))}
};*/
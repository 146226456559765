import React from 'react'
import { FaFilePdf } from "react-icons/fa6";

export default function Document({docName, docSection ,apiClient}) {
  
    const getOpenPdf = async (name) => {
        
        let result = await apiClient.documentGET2(name);    

             var byteCharacters = atob(result.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var file = new Blob([byteArray], { type: 'application/pdf;base64' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
    }

    return (
    <div className='DocView' onClick={() => getOpenPdf(docName)}>
        <FaFilePdf size='4vw' className='PDFIcon'/>
        <div className='docTitle'>{docName}</div>
    </div>
  )
}

import React from 'react'

import scheme from "../images/scheme.png"



export default function Contacts() {

  return (
    <div className='contactSheet' id='bebra'>
      <div className='half'>
        <div>Контактные данные нашего СНТ</div>
        <div>СНТ "Солнечное"</div>
        <div>ИНН: 5044020268</div>
        <div>КПП: 504401001</div>
        <div>Почтовый индекс: 141533</div>
        <div>Юридический адрес: Московская область, г.Солнечногорск, д. Овсянниково, СНТ "Солнечное"</div>
        <div>Председатель: Федоров Борис Федорович</div>
        <div>Основной email: boris.fiodorov@yandex.ru</div>
        <div>Основной номер телефона: +79168711856 СНТ "Солнечное"</div>
      </div>
        
     
    </div>

  )
}


//<img src={scheme} className='imgScheme'/>
import React, { useState } from 'react'
import Debbts from './Debbts';
import LatestNotifications from './LatestNotifications';

export default function NoteDebSwitch({apiClient, notifs}) {
    let [currPos, setCurrPos] = useState(false);
    let [currNote, setCurrNote] = useState('linked');    
    let [currDebt, setCurrDebt] = useState('unlinked');

    const switcherOne = () => {
        setCurrPos(false);
        setCurrNote('linked');
        setCurrDebt('unlinked');
    }

    const switcherTwo = () => {
        setCurrPos(true);
        setCurrNote('unlinked');
        setCurrDebt('linked');
    }
    
  return (
    <div className='mySwitch'>
        <div className='btnGroup'>
            <div className='left'>
            <div className={currNote} onClick={switcherOne}>Последние объявления</div>
            </div>
            <div className='right'>
            <div className={currDebt} onClick={switcherTwo}>Задолженности</div>
            </div>
        </div>
        {currPos && 
            (<Debbts/>)
            }
        {!currPos && 
        (<LatestNotifications apiClient={apiClient} notifs={notifs}/>)
        }
    </div>
  )
}

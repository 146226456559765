import React, { useEffect, useState, createContext } from "react";
import PropTypes from "prop-types";
//import { getImages } from "../../../imagesApi";



import Client from "../../../Api/Api.ts"

import Arrows from "./components/Controls/Arrows";
import Dots from "./components/Controls/Dots";

import SlidesList from "./components/SlidesList";


let data = [];

let callerArray = [false];

export const SliderContext = createContext();




const Slider = function ({ width, height, autoPlay, autoPlayTime, itemsB4 ,client, getImages}) {
  
  const [items, setItems] = useState([]);
  const [imageLoader, setLoader] = useState(0);
  const [slide, setSlide] = useState(0);
  
  const [touchPosition, setTouchPosition] = useState(null);


  

  
    useEffect(() => {    
    const loadData = async (check) => {  
      if(imageLoader === itemsB4.length)
      {
        callerArray = [];
        data = [];
        return;
      }
      if(itemsB4.length === 0)
        {
          return;
        }
      
      if(callerArray[imageLoader] === true)
        {
          return;
        }
        callerArray[imageLoader] = true;
        const image = await getImages(itemsB4[imageLoader].noteParent, itemsB4[imageLoader].name);        

        setLoader(imageLoader+1);
        items.push(image);
    };
    loadData(1);   
  }, [imageLoader, itemsB4]); 


  const changeSlide = (direction = 1) => {
    let slideNumber = 0;

    if (slide + direction < 0) {
      slideNumber = itemsB4.length - 1;
    } else {
      slideNumber = (slide + direction) % itemsB4.length;
    }

    setSlide(slideNumber);
  };

  const goToSlide = (number) => {
    setSlide(number % itemsB4.length);
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;

    setTouchPosition(touchDown);
  }

  const handleTouchMove = (e) => {
    if (touchPosition === null) {
      return;
    }

    const currentPosition = e.touches[0].clientX;
    const direction = touchPosition - currentPosition;

    if (direction > 10) {
      changeSlide(1);
    }

    if (direction < -10) {
      changeSlide(-1);
    }

    setTouchPosition(null);
  }

  useEffect(() => {
    if (!autoPlay) return;

    const interval = setInterval(() => {
      changeSlide(1);
    }, autoPlayTime);

    return () => {
      clearInterval(interval);
    };
  }, [itemsB4.length, slide]); // when images uploaded or slide changed manually we start timer

  return (
    <div
      style={{ width, height }}
      className="slider"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <SliderContext.Provider
        value={{
          goToSlide,
          changeSlide,
          slidesCount: itemsB4.length,
          slideNumber: slide,
          items
        }}
      >
        <Arrows />
        <SlidesList />
        <Dots />
      </SliderContext.Provider>
    </div>
  );
};

Slider.propTypes = {
  autoPlay: PropTypes.bool,
  autoPlayTime: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string
};

Slider.defaultProps = {
  autoPlay: false,
  autoPlayTime: 7000,
  width: "100%",
  height: "100%"  
};

export default Slider;

import React from 'react'

export default function Footer() {
  return (
    <footer>
        <div>All rights reserved &copy;</div>
        <div>
          <div>ИНН: 5044020268</div>
          <div className='mail'>Основной email: boris.fiodorov@yandex.ru</div>
        </div>
    </footer>
  )
}

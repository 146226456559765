import React, { useEffect, useState } from 'react'
import { Client, DocumentVm} from '../Api/Api.ts'
import Document from './Document.js';
import { DocPlacer } from '../Api/Api.ts';



export default function Documents({apiClient}) {
    const [docs, setDocs] = useState([]);
    const [secs, setSecs] = useState([]);

    let checked = 0;

    useEffect(() => {
        const loadData = async (check) => {  
            let result = await apiClient.documentGET();
            let docSec = result.docs[0].docSec;      
         
  result.docs.forEach(element => {
    if(!secs.includes(element.docSection))
    {
      secs.push(element.docSection);
      docSec = element.docSection;
    }
    
  });
  checked = 1;
 
 
  

            setDocs(result.docs);
          };
          loadData(1);   
        }, []); 

        

  return (
    <div className='docsHolder'> 
        {secs.map(els => (  <div className='section'>
                                <div className='headOfSec'>{els}</div>
                                <div className='docsArray'>
                                { docs.filter((elm) => elm.docSection === els).map(el => ( 
                                <Document docName={el.docName} apiClient={apiClient} />))}
                                </div>
                            </div>))}


        
    </div>
  )
}
/*Documents.propTypes = {
    apiClient: Client
  };*/


          
  /**/
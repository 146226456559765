import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { Client } from '../src/Api/Api.ts'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Notification from "./Components/Notification.js";

import { useEffect, useState } from 'react'

import Home from "./Components/mainPage.js";
import Documents from "./Components/Documents.js";
import React from 'react'
import Contacts from "./Components/Contacts.js";

import NotificationView from "./Components/NotificationView.js";



const apiClient = new Client('https:///snt-solnechnoe-api-test.ru')



function App() {
  const [notifs, setNotifs] = useState([]);

async function getNotifListLastSix() {
  const result = await apiClient.notificationGET(6);
  setNotifs(result.notificationCutVms);
} 
useEffect(() => {
  setTimeout(getNotifListLastSix, 700);
}, []);


  return (
    <div className="wraper">


       <BrowserRouter>
       <Routes>
      <Route path="/" element={<Header />}>
        <Route index element={<Home apiClient={apiClient} notifs={notifs}/>} />
        <Route path="documents" element={<Documents apiClient={apiClient}/>} />
        
          {notifs.map(el => (
          <Route path={el.title} element={<Notification className='noteNow' noteName={el.title} apiClient={apiClient} key={el.index+1}/>} />
        ))}
        <Route path="contacts" element={<Contacts/>} />

      </Route>

    </Routes>
    </BrowserRouter>

  

      <Footer />
    </div>
  );
}

export default App;


/*<Route path="blogs" element={<Blogs />} />
<Route path="contact" element={<Contact />} />*/

//<Route path="/" element={<LatestNotifications apiClient={apiClient} notifs={notifs}/>}>  

//</Route>      
     /**/

<NotificationView apiClient={apiClient}/>



     /**/
import React from 'react'
import LatestNotifications from './LatestNotifications'
import Notification from './Notification'
import Debbts from './Debbts'
import NoteDebSwitch from './NoteDebSwitch'

export default function Home({apiClient, notifs}) {
  return (
    <div className="main">
    <NoteDebSwitch apiClient={apiClient} notifs={notifs}/>
    <Notification noteName={"Про нас"} apiClient={apiClient} key={0}/>
    </div>
  )
}



//<Debbts/>
//<LatestNotifications apiClient={apiClient} notifs={notifs}/>
import React from 'react'
import { Outlet, Link } from "react-router-dom";

export default function Header(props) {
  return (
    <header>
        <div className='fullTop'>
            <Link className='logo' to="/">СНТ "CОЛНЕЧНОЕ"</Link>
            <ul className='nav'>
                <Link className='myLi' to="/">Про нас</Link>               
                <Link className='myLi' to="/documents">Документы</Link>
                <Link className='myLi' to="/contacts">Контакты</Link>
            </ul>            
        </div>
        <div className='presentation'></div>
        <Outlet />            

    </header>
    
  )
}
/*            */
import React, { useState } from 'react'
import elDebts from './elDebts'

export default function Debbts() {
    
    let [tblOpen, setTableOpen] = useState(false)
    let [tblOpen1, setTableOpen1] = useState(false)
    
    const inDebts = 
         [
            {
                propNum: '№6',
                check: '',
                debPeriods: [
                    '2023 1,2,3,4 кв.', 
                    '2024 1 кв.'],
                periodDebts: [
                    12000,
                    3000
                ],
                fullDebt: 15000                
            },
            {
                propNum: '№21',
                check: '',
                debPeriods: [
                    '2023 4 кв.', 
                    '2024 1 кв.'],
                periodDebts: [
                    3000,
                    3000
                ],
                fullDebt: 6000                
            },
            {
                propNum: '№25',
                check: '',
                debPeriods: [
                    '2023 ноябрь, декабрь'],
                periodDebts: [
                    2000
                ],
                fullDebt: 2000                
            },
            {
                propNum: '№26',
                check: '',
                debPeriods: [
                    '2023 ноябрь, декабрь.'],
                periodDebts: [
                    2000
                ],
                fullDebt: 2000                
            },
         
            {
                propNum: '№28',
                check: 'Данные не поданы',
                debPeriods: [
                    '2019 3,4 кв.',
                    '2020 1,2,3,4 кв.',
                    '2021 1,2,3,4 кв.',
                    '2022 1,2,3,4 кв.',
                    '2023 1,2,3,4 кв.',
                    '2024 1 кв.'],
                periodDebts: [
                    6000,
                    12000,
                    12000,
                    12000,
                    12000,
                    3000                    
                ],
                fullDebt: 57000                
            },
            {
                propNum: '№30',
                check: 'Данные не поданы',
                debPeriods: [
                    '2020 1,2,3,4 кв.',
                    '2021 1,2,3,4 кв.',
                    '2022 1,2,3,4 кв.',
                    '2023 1,2,3,4 кв.',
                    '2024 1 кв.'],
                periodDebts: [
                    12000,
                    12000,
                    12000,
                    6000,
                    3000                    
                ],
                fullDebt: 45000                
            },
            {
                propNum: '№34',
                check: '',
                debPeriods: [
                '2019 3,4 кв.',
                '2020 1,2,3,4 кв.',
                '2021 1,2,3,4 кв.',
                '2022 1,2,3,4 кв.',
                '2023 1,2,3,4 кв.',
                '2024 1 кв.'
                ],
                periodDebts: [
                    6000,
                    12000,
                    12000,
                    12000,
                    12000,
                    3000                                       
                ],
                fullDebt: 57000
            },
            {
                propNum: '№35',
                check: '',
                debPeriods: [
                    '2023 сентябрь',
                    '2023 4 кв.',
                    '2024 1 кв.'
                    ],
                periodDebts: [
                    1000,
                    3000,
                    3000                   
                ],
                fullDebt: 7000                
            },
            {
                propNum: '№36',
                check: '',
                debPeriods: [
                    '2023 1,2,3,4 кв.',
                    '2024 1 кв.'                    
                    ],
                periodDebts: [
                    12000,
                    3000                   
                ],
                fullDebt: 12000                
            },
            {
                propNum: '№44',
                check: '',
                debPeriods: [
                    '2021 3,4 кв.',
                    '2022 1,2,3,4 кв.',
                    '2023 1,2,3,4 кв.',
                    '2024 1 кв.'                                       
                    ],
                periodDebts: [
                    6000,
                    12000,
                    12000,
                    3000               
                ],
                fullDebt: 33000                
            },
            {
                propNum: '№45',
                check: '',
                debPeriods: [
                    '2020 3,4 кв.',
                    '2021 1,2,3,4 кв.',
                    '2022 1,2,3,4 кв.',
                    '2023 1,2,3,4 кв.'                                                           
                    ],
                periodDebts: [
                    6000,
                    12000,
                    12000,
                    12000                                 
                ],
                fullDebt: 42000                
            },
            {
                propNum: '№46',
                check: '',
                debPeriods: [
                    '2023 1,2,3 кв.'
                    ],
                periodDebts: [
                    9000                                 
                ],
                fullDebt: 9000                
            },
            {
                propNum: '№53',
                check: 'Данные не поданы',
                debPeriods: [
                    '2018 4 кв.',
                    '2019 1,2,3,4 кв.',
                    '2020 1,2,3,4 кв.',
                    '2021 1,2,3,4 кв.',
                    '2022 1,2,3,4 кв.',                    
                    '2023 1,2,3,4 кв.',
                    '2024 1 кв.'                    
                    ],
                periodDebts: [
                    2100,
                    10200,
                    12000,
                    12000,
                    12000,
                    12000,
                    3000                                                   
                ],
                fullDebt: 63300                
            },
            {
                propNum: '№59',
                check: 'Данные не поданы',
                debPeriods: [
                    '2019 2,3,4 кв.',
                    '2020 1,2,3,4 кв.',
                    '2021 1,2,3,4 кв.',
                    '2022 1,2,3,4 кв.',                    
                    '2023 1,2,3,4 кв.',
                    '2024 1 кв.'                    
                    ],
                periodDebts: [
                    8100,                    
                    12000,
                    12000,
                    12000,
                    12000,
                    3000                                                   
                ],
                fullDebt: 59100                
            },
               {
                propNum: '№73',
                check: 'Собственник не определен',
                debPeriods: [
                    'Нет оплат с 2001 г.'
                    ],
                periodDebts: [
                ],
                fullDebt: ''            
            },
            {
                propNum: '№74',
                check: 'Под арестом',
                debPeriods: [ 
                    'Нет оплат с 2013 г.'
                    ],
                periodDebts: [
                ],
                fullDebt: ''            
            },
            {
                propNum: '№74a',
                check: '',
                debPeriods: [
                    '2015 3,4 кв.',
                    '2016 1,2,3,4 кв.',
                    '2017 1,2,3,4 кв.',
                    '2018 1,2,3,4 кв.',
                    '2019 1,2,3,4 кв.',
                    '2020 1,2,3,4 кв.',
                    '2021 1,2,3,4 кв.',
                    '2022 1,2,3,4 кв.',
                    '2023 1,2 кв.',
                    '2023 август'                    
                    ],
                periodDebts: [
                    3480,
                    7680,
                    8400,
                    8400,
                    10200,
                    12000,
                    12000,
                    12000,
                    6000,
                    1000                    
                ],
                fullDebt: 81160
            },
            {
                propNum: '№75',
                check: '',
                debPeriods: [
                    '2020 2,3,4 кв.'                
                    ],
                periodDebts: [
                    9000                
                ],
                fullDebt: 9000               
            },
            {
                propNum: '№76',
                check: '',
                debPeriods: [
                    '2019 июль',
                    'август',
                    'сентябрь',
                    'октябрь',
                    'ноябрь',
                    'декабрь',
                    '2020 январь',
                    'февраль',
                    'март',
                    'апрель',
                    'май',
                    'июнь',
                    'июль',
                    'август',
                    'сентябрь',
                    'октябрь',
                    'ноябрь',
                    'декабрь',
                    '2021 январь',
                    'февраль',
                    'март',
                    'апрель',
                    'май',
                    'июнь',
                    '3,4 кв.',
                    '2022 1,2,3,4 кв.',
                    '2023 1,2 кв.',
                    '2023 август'                                                         
                    ],
                periodDebts: [
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    300,
                    6000,
                    12000,
                    6000,
                    1000,                                                        
                ],
                fullDebt: 32200                
            },
            {
                propNum: '№79',
                check: '',
                debPeriods: [
                    '2019 1,2,3,4 кв.',
                    '2020 1,2,3,4 кв.',
                    '2021 1,2,3,4 кв.',
                    '2022 1,2,3,4 кв.',
                    '2023 1,2,3,4 кв.',
                    '2024 1 кв.'                                                           
                    ],
                periodDebts: [
                    10200,
                    12000,
                    12000,
                    12000,
                    12000,
                    3000
                ],
                fullDebt: 61200                
            },
            {
                propNum: '№83',
                check: '',
                debPeriods: [
                    '2023 4 кв.',
                    '2024 1 кв.'                                                          
                    ],
                periodDebts: [
                    3000,
                    3000                                                       
                ],
                fullDebt: 6000                
            }

        ]

  return (
    <div debtsNote className>
    <div className='debtsText'><p>Уважаемые садоводы.</p>
<p>Проверьте Ваши оплаты, в случае наличия неучтенных оплат свяжитесь с секретарем Правления для сверки данных.</p>
</div>

    <div className='unwrapper' onClick={() => setTableOpen(tblOpen = !tblOpen)}> Просмотреть полный список задолженностей по членским взносам</div>
        {tblOpen && (
                <div>
                <div className='DebtsTable'>
                <div className='tblHeader'><font color="red">Список задолженностей на 20.05.2024 гг.</font></div>
                <div className='tblRows'>
                    <div>Номер участка</div>
                    <div>Период задолженности</div>
                    <div>Сумма задолженности по периодам</div>
                    <div>Общая сумма задолженности</div>
                </div>
            {inDebts.map(el => 
            (
                <div className='singleRow'>
                    <div className='firstCol'>
                        <div>{el.propNum}</div>
                        <div className='checkProp'>{el.check}</div>
                    </div>
                    <div className='periods'>
                    {el.debPeriods.map(els =>
                        (
                            <div >{els}</div>
                        )
                        )}
                    </div>
                    <div className='periodDebs'>
                    {el.periodDebts.map(els =>
                        (
                            <div>{els}</div>
                        )
                        )}
                    </div>
                    <div className='fullDebt'>{el.fullDebt}</div>
                </div>
            ))}
            </div>
                        <div className='debtsText'>Участки без предоставления данных о собственности, неплательщики, заброшенные: 28,30,53,59,73,74,79. Такие участки подлежат изъятию согласно законодательству РФ (ст. 285 ГК РФ) как использующиеся не по целевому назначению . Запрос от Администрации района о наличии таких участков был, <font color="red">повторно</font>, <u>пока</u> данные не подавались.
Согласно документам собственник участка 73 не определен, а участок 74 под арестом.
    </div> 
            </div>)}

            <div className='debtsText'>
                <p>Мы подаем показания общего счетчика СНТ <font color='red'>ежемесячно</font> в МОСЭНЭРГО и, соответственно, оплачиваем выставленные нам счета <font color='red'>ежемесячно</font>, иначе нам просто отключат электроэнергию (не смотря ни на какие прямые договора).</p>
                <p>В таблице дана информация о датах последних внесенных собственниками оплат за потребленное электричество (на 15.05.2024 г.)</p>
                <p>Из каких средств СНТ должен оплачивать каждый месяц разницу между оплаченной и потребленной собственниками электроэнергией?</p>
</div>

            <div className='unwrapper' onClick={() => setTableOpen1(tblOpen1 = !tblOpen1)}> Просмотреть полный список задолженностей по оплатам за электроснабжение</div>
        {tblOpen1 && (
                <div>
                <div className='DebtsTable'>
                <div className='tblHeader'><font color="red">Оплата электричества СНТ «Солнечное» на 15.05.2024г.</font></div>
                <div className='tblRows'>
                    <div>Номер участка</div>
                    <div>Дата последней оплаты</div>
                    <div>Долг по оплате электричества из-за ошибки в расчетах </div>
                    <div>Примечания</div>
                </div>
            {elDebts.map((el, index) => 
            (
                <div className='singleRow'>
                    <div className='firstCol' >
                        <font color={el.clrCode}>{!el.propNum ? <div>{index+1}</div> : <div>{el.propNum}</div>} </font>
                    </div>
                    <div className='periods'>
                    <font color={el.clrCode}>{el.lastPay}</font>
                    </div>
                    <div className='periodDebs'>
                    <font color={el.clrCode}>{el.debt}</font>
                    </div>
                    <div className='trivia' > <font color={el.clrCode}>{el.trivia}</font></div>

                </div>
            ))}
            </div>
                        <div className='debtsText'>Участки без предоставления данных о собственности, неплательщики, заброшенные: 28,30,53,59,73,74,79. Такие участки подлежат изъятию согласно законодательству РФ (ст. 285 ГК РФ) как использующиеся не по целевому назначению . Запрос от Администрации района о наличии таких участков был, <font color="red">повторно</font>, <u>пока</u> данные не подавались.
Согласно документам собственник участка 73 не определен, а участок 74 под арестом.
    </div> 
            </div>)}






            <div className='debtsText'><p>Задолжности в 2024 году:</p>
<p>За 1 кв.2024 г: 8, 10, 12, 27, 29, 33, 48, 50,54,  61, 69, 71, 78,  80, 81, 85.</p>
<p>За февраль 2024 г.:  64</p>
<p>За март 2024 г.: 11, 64</p>
</div>
<div className='debtsText'>ЗАДОЛЖНОСТИ ПО ИСПОЛНИТЕЛЬНЫМ ЛИСТАМ по суду первой инстанции 	и апелляционному:
</div>
<div className='debtsText'><font color="red">Участки 20, 31</font></div>

    </div>
  )
}


//<div className='headerOfDebts'>Задолженности по оплате</div>
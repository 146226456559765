const elDebts = 
[
  {
    "propNum": "1",
    "lastPay": "19.11.2023"
  },
  {
    "propNum": "2",
    "lastPay": "21.09.2019",
    "trivia": "Участок был обесточен",
    "clrCode": "orange"
  },
  {
    "propNum": "3",
    "lastPay": "19.11.2023"
  },
  {
    "propNum": "4",
    "lastPay": "21.09.2019",
    "trivia": "Участок был обесточен",
    "clrCode": "orange"
  },
  {
    "propNum": "5",
    "lastPay": "26.10.2023"
  },
  {
    "propNum": "6",
    "lastPay": "13.07.2020",
    "clrCode": "red"
  },
  {
    "propNum": "7",
    "lastPay": "26.10.2023"
  },
  {
    "propNum": "8",
    "lastPay": "10.01.2024"
  },
  {
    "propNum": "9",
    "lastPay": "10.04.2024"
  },
  {
    "propNum": "10",
    "lastPay": "12.09.2023",
    "debt": "246",
    "trivia": "Ошибка расчета собственником"
  },
  {
    "propNum": "11",
    "lastPay": "18.11.2023"
  },
  {
    "propNum": "12",
    "lastPay": "08.08.2022",
    "trivia": "Не сданы показания старого счетчика",
    "clrCode": "red"
  },
  {
    "propNum": "13",
    "lastPay": "18.11.2023"
  },
  {
    "propNum": "14",
    "lastPay": "07.09.2023",
    "debt": "2866.37",
    "trivia": "Расчет по старому тарифу собственником"
  },
  {
    "propNum": "15",
    "lastPay": "25.05.2023"
  },
  {
    "propNum": "16",
    "lastPay": "04.09.2023",
    "debt": "174.21",
    "trivia": "Ошибка расчета (08.09.2022) собственником"
  },
  {
    "propNum": "17",
    "lastPay": "Участок обесточен"
  },
  {
    "propNum": "18",
    "lastPay": "05.09.2023"
  },
  {
    "propNum": "19",
    "lastPay": "Прямой договор"
  },
  {
    "propNum": "20",
    "lastPay": "23.11.2023"
  },
  {
    "propNum": "21",
    "lastPay": "30.08.2022",
    "trivia": "Участок практически не посещается ",
    "clrCode": "orange"
  },
  {
    "propNum": "22",
    "lastPay": "Прямой договор"
  },
  {
    "propNum": "23",
    "lastPay": "03.07.2023"
  },
  {
    "propNum": "24",
    "lastPay": "06.09.2023"
  },
  {
    "propNum": "25",
    "lastPay": "16.01.2023",
    "trivia": "Не сданы показания старого счетчика",
    "clrCode": "red"
  },
  {
    "propNum": "26",
    "lastPay": "Прямой договор"
  },
  {
    "propNum": "27",
    "lastPay": "28.08.2023"
  },
  {
    "propNum": "28",
    "lastPay": "Участок обесточен"
  },
  {
    "propNum": "29",
    "lastPay": "31.07.2023"
  },
  {
    "propNum": "30",
    "lastPay": "Участок обесточен"
  },
  {
    "propNum": "31",
    "lastPay": "20.12.2023"
  },
  {
    "propNum": "32",
    "lastPay": "           ?"
  },
  {
    "propNum": "33",
    "lastPay": "10.12.2021",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "34",
    "lastPay": "27.07.2019",
    "clrCode": "red"
  },
  {
    "propNum": "35",
    "lastPay": "16.09.2022",
    "debt": "771.25",
    "trivia": "Расчет по старому тарифу собственником",
    "clrCode": "red"
  },
  {
    "propNum": "36",
    "lastPay": "20.05.2024",
    "debt": "408.8",
    "trivia": "Ошибка расчета собственником"
  },
  {
    "propNum": "37",
    "lastPay": "12.12.2023"
  },
  {
    "propNum": "38",
    "lastPay": "23.10.2023",
    "debt": "465.09",
    "trivia": "Расчет по старому тарифу собственником"
  },
  {
    "propNum": "39",
    "lastPay": "Оплата авансом вперед"
  },
  {
    "propNum": "40",
    "lastPay": "04.10.2023"
  },
  {
    "propNum": "41",
    "lastPay": "Оплата авансом вперед"
  },
  {
    "propNum": "42",
    "lastPay": "30.11.2023"
  },
  {
    "propNum": "43",
    "lastPay": "10.01.2024"
  },
  {
    "propNum": "44",
    "lastPay": "28.09.2019",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "45",
    "lastPay": "26.06.2023"
  },
  {
    "propNum": "46",
    "lastPay": "05.07.2022",
    "trivia": "Не сданы показания старого счетчика",
    "clrCode": "red"
  },
  {
    "propNum": "47",
    "lastPay": "07.05.2024"
  },
  {
    "propNum": "48",
    "lastPay": "31.12.2023"
  },
  {
    "propNum": "49",
    "lastPay": "25.04.2024"
  },
  {
    "propNum": "50",
    "lastPay": "04.05.2022",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "51",
    "lastPay": "12.02.2024"
  },
  {
    "propNum": "52",
    "lastPay": "09.10.2023"
  },
  {
    "propNum": "53",
    "lastPay": "? "
  },
  {
    "propNum": "54",
    "lastPay": "25.12.2023",
    "trivia": "Без показаний"
  },
  {
    "propNum": "55",
    "lastPay": "13.11.2023"
  },
  {
    "propNum": "56",
    "lastPay": "06.05.2024",
    "trivia": "Без показаний "
  },
  {
    "propNum": "57",
    "lastPay": "21.03.2024",
    "debt": "4975.39",
    "trivia": "Расчет по старому тарифу собственником"
  },
  {
    "propNum": "58",
    "lastPay": "20.07.2021",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "59",
    "lastPay": "Участок обесточен"
  },
  {
    "propNum": "60",
    "lastPay": "11.10.2023"
  },
  {
    "propNum": "61",
    "lastPay": "",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "62",
    "lastPay": "",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "63",
    "lastPay": "Объединен с уч.65"
  },
  {
    "propNum": "64",
    "lastPay": "",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "65",
    "lastPay": "27.12.2023",
    "debt": "566.1",
    "trivia": "Расчет по старому тарифу собственником"
  },
  {
    "propNum": "66",
    "lastPay": "18.03.2024",
    "debt": "813.8",
    "trivia": "Расчет по старому тарифу собственником"
  },
  {
    "propNum": "67",
    "lastPay": "",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "68",
    "lastPay": "10.04.2024",
    "debt": "9190.25",
    "trivia": "Расчет по старому тарифусобственником"
  },
  {
    "propNum": "69",
    "lastPay": "16.06.2023",
    "trivia": "Без показаний"
  },
  {
    "propNum": "70",
    "lastPay": "27.11.2023"
  },
  {
    "propNum": "71",
    "lastPay": "25.09.2023"
  },
  {
    "propNum": "72",
    "lastPay": "  ",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "73",
    "lastPay": "",
    "trivia": "Участок практически не посещается",
    "clrCode": "orange"
  },
  {
    "propNum": "74",
    "lastPay": "Участок обесточен"
  },
  {
    "propNum": "75",
    "lastPay": "14.05.2024"
  },
  {
    "propNum": "76",
    "lastPay": "Прямой договор"
  },
  {
    "propNum": "77",
    "lastPay": "26.04.2024",
    "trivia": "Без показаний"
  },
  {
    "propNum": "78",
    "lastPay": "04.02.2024"
  },
  {
    "propNum": "79",
    "lastPay": "15.04.2020",
    "clrCode": "red"
  },
  {
    "propNum": "80",
    "lastPay": "?  "
  },
  {
    "propNum": "81",
    "lastPay": "25.08.2023",
    "debt": "5476.78",
    "trivia": "Ошибка расчета собственником"
  },
  {
    "propNum": "82",
    "lastPay": "Прямой договор"
  },
  {
    "propNum": "83",
    "lastPay": "06.05.2024"
  },
  {
    "propNum": "84",
    "lastPay": "Прямой договор"
  },
  {
    "propNum": "85",
    "lastPay": "18.07.2023"
  },
  {
    "propNum": "74а",
    "lastPay": "14.11.2022",
    "clrCode": "red"
  }
]

export default elDebts